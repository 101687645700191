.breadcrumbs-container{
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);*/
}

.breadcrumbs{
    padding: 30px;
}

@media (max-width: 768px) {
    .breadcrumbs {
        padding: 10px;
    }
}

.breadcrumbs-tab{
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    text-align: center;
    display: inline-block;
    width: 90px;
}

@media (max-width: 768px) {
    .breadcrumbs-tab {
        font-size: 0.7rem !important;
        width: 65px;
    }
}

@media (max-width: 768px) {
    .MuiBreadcrumbs-separator {
        margin: 2px 2px !important;
    }
}

.footer{
    text-align: center;
    padding: 5%;
    color: dimgray;
}

.content{
    /*padding-top: 5vh;*/
    height: auto;
    flex: 1;
    /*display: inline-block;*/
}

.listFatherButton{
    padding-left: 5%;
    color: dimgrey;
}

@media (max-width: 768px) {
    .listFatherButton {
        font-size: 0.5rem;
    }
}

.listFatherSubheader{
    /*padding-left: 5%;*/
    color: dimgrey;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .listFatherSubheader {
        font-size: 0.7rem;
        line-height: 20px;
    }
}

.listChildButton{
    padding-left: 15%;
    color: dimgrey;
}

@media (max-width: 768px) {
    .listChildButton {
        font-size: 0.5rem;
    }
}

.smallImage{
    width: 100%;
    height: auto;
    cursor: pointer;
}

.icon{
    color: dimgray;
}

@media (max-width: 768px) {
    .icon {
        font-size: 0.5rem !important;
    }
}

.paragraph{
    padding-bottom: 20px;
    color: #464646;
}

.marker {
    background-image: url('../public/icon/pin.png');
    background-size: cover;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.previewCard{
    margin: 0 5% 3%;
    border-radius: 20px;
    border: lightgrey 1px solid;
}

.previewImage{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.previewText{
    align-content: center;
}

.previewTitle{
    color: #464646;
}

.previewDate{
    color: dimgrey;
}

.previewContent{
    color: dimgrey;
    padding-top: 4%;
}

.blogTitle{

}

.blogDate{

}

.blogSmallImage{
    width: 50%;
    margin: 0 auto;
    display: block;
    /*padding-top: 3%;*/
}

@media (max-width: 768px) {
    .blogSmallImage {
        width: 70%;
    }
}

.blogImage{
    width: 80%;
    margin: 0 auto;
    display: block;
    /*padding-top: 3%;*/
}

@media (max-width: 768px) {
    .blogImage {
        width: 100%;
    }
}

.blogVerticalImage{
    width: 45%;
    margin: 0 auto;
    display: block;
    /*padding-top: 3%;*/
}

@media (max-width: 768px) {
    .blogVerticalImage {
        width: 70%;
    }
}

.blogGridImage{
    width: 95%;
    height: 100%;
    margin: 0 auto;
    display: block;
    object-fit: cover;
    /*padding-top: 3%;*/
}

.blogCaption{
    color: #464646;
    font-size: 15px !important;
    text-align: center;
    display: block;
    padding-bottom: 25px;
}

.bolgContent{
    padding-top: 5%;
}

.blogText{
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .blogText {
        width: 90%;
    }
}

.blogParagraph{
    padding-bottom: 25px;
    color: #464646;
}

.musicBlock{
    margin-bottom: 5%;
}

body {
    font-family: 'Verdana', 'Geneva', 'sans-serif', sans-serif;
}

/*@media (max-width: 768px) {*/
/*    body {*/
/*        font-size: 0.5rem !important;*/
/*    }*/
/*}*/
